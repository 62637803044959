<div class="container-partner">
  <mat-toolbar style="background-color: var(--color-gray-600); color: var(--color-white); min-height: 64px;">
    <span>Lista de Parceiros</span>
  </mat-toolbar>

  <div class="input-container">
        <input class="form-input" placeholder="Buscar parceiro" [(ngModel)]="search"/>
        <button class="button" (click)="eraserAll()"><mat-icon>search</mat-icon></button>
  </div>

  <div fxFlex class="card-info">
    <span fxFlex="20" class="content">Nome</span>
    <span fxFlex="16" class="content">E-mail</span>
    <span fxFlex="20" class="content">Telefone</span>
    <span fxFlex="19" class="content">Código</span>
    <span fxFlex="20" class="content">Status</span>
  </div>

  <div
    class="cards-section"
    infiniteScroll
    [scrollWindow] = "false"
    [infiniteScrollThrottle]="300"
    (scrolled)="onScroll()">
    <div *ngFor="let partner of partners" class="partner-card">
            <div fxFlex="80" class="card-text">
              <span fxFlex="25" class="content">{{partner.name| slice:0:12}}{{partner.name.length > 12 ? '...' : '' }}</span>
              <span fxFlex="25" class="content">{{partner.email}}</span>
              <span fxFlex="25" class="content">{{partner.phone}}</span>
              <span fxFlex="25" class="content">{{partner.shareableCode}}</span>
            </div>

            <span fxFlex="7">
              <div *ngIf="partner?.users[0]?.active == true" class="status-active"></div>
              <div *ngIf="partner?.users[0]?.active == false" class="status-inactive"></div>
            </span>

            <div fxFlex="20" class="card-buttons">
              <button class="button"
              matTooltip="Acessar"
              (click)="openPage(partner.users[0].user)"
              [disabled]="!partner.users[0].active"
              [ngClass]="{'disabled-button': partner.users[0].active == false}"
              ><mat-icon>redo</mat-icon></button>

              <button class="button" matTooltip="Carteira" (click)="openPartnersWalletDialog(partner._id)"><mat-icon>account_balance_wallet</mat-icon></button>

              <button class="button" matTooltip="Visualizar" (click)="openPartnersDialog(partner)"><mat-icon>visibility</mat-icon></button>
            </div>
    </div>
  </div>
  <button class="add-button" mat-fab>
    <mat-icon>add</mat-icon>
  </button>

</div>
