import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  changeToken = new BehaviorSubject<Boolean>(true)

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private appComponent: AppComponent,
    private snackBar: MatSnackBar
    ) { }
    private url = environment.BACKEND_URL

    hasToken(){
      const token = localStorage.getItem('access_token')
      if(token) {
        return true
      }
        return false
    }

  login(login:any) {
    this.httpClient.post(`${this.url}/noauth/login`,login).subscribe( (res: any) => {
      if(!res.success) {
        return console.log(res.message);
      }
      this.showHasToken(true)
      localStorage.setItem('access_token', res.token);
      this.router.navigate(['/home'])
    }, (error =>{
      this.snackBar.open(error.message, 'Fechar', {
        duration: 3000,
      });
    }))

  }

  googleLogin(idToken:string) {
    return this.httpClient.post(`${this.url}/noauth/google-auth/generate-token`,{code: idToken})
  }

  logOut(){
    localStorage.removeItem('access_token')
    this.router.navigate([''])
  }


  showHasTokenObs(){
    return this.changeToken.asObservable()
  }

  showHasToken(state: boolean){
    this.changeToken.next(state)
  }
}
