<div class="container-home">
  <mat-toolbar style="background-color: var(--color-gray-600); color: var(--color-white);">
    <span>Relatório</span>
  </mat-toolbar>
  <mat-card class="home-mat-card-container">
    <mat-card-content class="home-container">
      <p class="title">Quantidade de Parceiros</p>
      <p class="number">1982</p>
    </mat-card-content>
  </mat-card>
</div>
