import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { MatSidenavModule} from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';




import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { PartnersListComponent } from './pages/partners-list/partners-list.component';
import { HomeComponent } from './pages/home/home.component';
import { SideMenuComponent } from './component/side-menu/side-menu.component';
import { ViewPartnersComponent } from './modal/view-partners/view-partners.component';
import { CreatePartnerComponent } from './modal/create-partner/create-partner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TokenInterceptorService } from './interceptors/token.interceptor';
import { CommonModule } from '@angular/common';
import { SocialLoginModule, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { WalletsInfoPartnersComponent } from './modal/wallets-info-partners/wallets-info-partners.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PartnersListComponent,
    HomeComponent,
    SideMenuComponent,
    ViewPartnersComponent,
    CreatePartnerComponent,
    WalletsInfoPartnersComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatToolbarModule,
    InfiniteScrollModule,
    CommonModule,
    FormsModule,
    SocialLoginModule,
    MatSlideToggleModule,
    MatListModule,
    MatTooltipModule,
    MatSnackBarModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatTabsModule,
    MatSelectModule,

  ],
  providers:[AppComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
