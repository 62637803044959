import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        authorization: `${token}`,
      },
    });
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('access_token');
    // console.log('intercepted request ... ');
    if (token && !request.headers.has('authorization')) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.error) {
          return throwError(error.error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
