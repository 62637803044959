<div class="container">
  <div class="login-title-container">
    <mat-icon class="icon" fontIcon="account_box"></mat-icon>
    <h2 class="title">Login</h2>
  </div>
  <form class="login-container" [formGroup]="form">
    <div class="input-container" style="margin-top: 2%">
      <mat-icon class="icon">person</mat-icon>
      <div class="login-input">
        <input class="input" formControlName="email" matInput type="email" placeholder="Email">
      </div>
    </div>
    <div class="input-container">
      <mat-icon class="icon">lock</mat-icon>
      <div class="login-input">
          <input class="input" formControlName="password" matInput type="password" placeholder="Senha">
      </div>
    </div>
    <button class="login-button" (click)="login()">ENTRAR</button>
    <asl-google-signin-button type="standard" size="large"></asl-google-signin-button>
  </form>
</div>
