<mat-tab-group>
  <mat-tab label="Carteira">
    <div>
      <mat-card-content class="main-card">

        <div class="card-card">
          <div class="card-wrapper">
              <div class="cards">
                <span class="balance-text">Saldo Disponível</span>
                <h1 class="balance-value">R$ {{ partner?.wallet?.available}}</h1>
              </div>

              <div class="cards" style="margin-left: 23% !important;">
                <span class="balance-text">Em processamento</span>
                <h1 class="balance-value">R$ {{ partner?.wallet?.blocked}}</h1>
              </div>
          </div>
        </div>
      </mat-card-content>

      <mat-card-content class="main-card">
        <div class="card-card">
          <p class="card-warning">Seu desempenho deste <strong>ultimo mês</strong></p>

          <div class="card-wrapper">
            <div style="width: 100%;">
              <div class="cards">
                <span class="balance-text">VENDAS</span>
                <h1 class="balance-value">{{partner?.performance[0]?.value}}</h1>
              </div>

              <div class="cards">
                <span class="balance-text">HOJE</span>
                 <h1 class="balance-value">R$ {{partner?.performance[2].value}}</h1>
              </div>
            </div>


            <div  style="width: 100%;">
              <div class="cards">
                <span class="balance-text">NESTE MÊS</span>
                <h1 class="balance-value">R$ {{partner?.performance[1].value}}</h1>
              </div>

              <div class="cards">
                <span class="balance-text">NESTA SEMANA</span>
                <h1 class="balance-value">R$ {{partner?.performance[3].value}}</h1>
              </div>
            </div>

          </div>
        </div>

      </mat-card-content>
    </div>
  </mat-tab>
  <mat-tab label="Histórico">
    <div>
      <mat-form-field>
        <mat-label>Filtrar por</mat-label>
        <mat-select [formControl]="form" (ngModelChange)="filterHistory($event)">
          <mat-option value="DEBIT">Saques</mat-option>
          <mat-option value="CREDIT">Ganhos</mat-option>
          <mat-option value="OPEN">Créditos em aberto</mat-option>
          <mat-option value="ALL" selected>Todos</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
    <div>
      <mat-card-content class="historic-card">
        <span class="text" *ngIf="withdraws?.length == 0">Sem Informações</span>
        <div *ngFor="let w of withdraws" class="transitions-card">
        <ng-container *ngIf="w?.type == 'DEBIT'">
          <div class="values-cards ">
            <span class="text">Origem:<strong>{{w?.source || '---'}}</strong></span>
            <span class="text">Responsável:<strong>{{w?.responsible?.name || '---'}} </strong></span>
            <span class="text">Data: <strong>{{w?.createdAt ? (w.createdAt | date:'dd/MM/yyyy HH:mm:ss') : 'Sem informação'}}</strong></span>
            <span class="text">ID do ganho: <strong>{{w?.creditId || '---'}}</strong></span>
          </div>
          <div class="values-cards">
            <span class="text right">{{w?.type}}</span>
            <span class="text right">{{w?.subject}}</span>
            <span class="text right"><strong>R$ {{w?.value}} </strong></span>
          </div>
        </ng-container>
        <ng-container *ngIf="w?.type == 'CREDIT'">
          <div class="values-cards ">
            <span class="text">Origem:<strong>{{w?.source || '---'}}</strong></span>
            <span class="text">Responsável:<strong>{{w?.responsible?.name || '---'}} </strong></span>
            <span class="text">Data: <strong>{{w?.createdAt ? (w.createdAt | date:'dd/MM/yyyy HH:mm:ss') : 'Sem informação'}}</strong></span>
            <button mat-raised-button color="primary" style="height: 24px;" (click)="liquidateCredit(w)" *ngIf="w?.showButton || form.value == 'OPEN'">Efetuar saque</button>
            <span class="text" *ngIf="!w?.showButton && form.value != 'OPEN'">ID do saque: <strong>{{w?.debitId || '---'}}</strong></span>
          </div>
          <div class="values-cards">
            <span class="text right">{{w?.type}}</span>
            <span class="text right">{{w?.subject}}</span>
            <span class="text right"><strong>R$ {{w?.value}} </strong></span>
          </div>
        </ng-container>
        
        </div>
      </mat-card-content>

    </div>
  </mat-tab>


  <mat-tab label="Liquidados">
    <mat-card-content class="historic-card">
      <span class="text" *ngIf="liquidated?.length == 0">Sem Informações</span>
      <div *ngFor="let l of liquidated" class="transitions-card">
        <div class="values-cards ">
          <span class="text">Origem:<strong>{{l?.source || '---'}}</strong></span>
          <span class="text">Responsável:<strong>{{l?.responsible?.name || '---'}} </strong></span>
          <span class="text">Data: <strong>{{l?.createdAt ? (l.createdAt | date:'dd/MM/yyyy HH:mm:ss') : 'Sem informação'}}</strong></span>
          <span class="text">ID do crédito: <strong>{{l?.creditId || '---'}}</strong></span>
        </div>
        <div class="values-cards">
          <span class="text right">{{l?.type}}</span>
          <span class="text right">{{l?.subject}}</span>
          <span class="text right"><strong>R$ {{l?.value}} </strong></span>
        </div>
      </div>
    </mat-card-content>
  </mat-tab>
</mat-tab-group>



