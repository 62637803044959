<div>
  <mat-toolbar style="background-color: var(--color-gray-600); color: var(--color-white); min-height: 64px;">
    <span>Edição de parceiro</span>
  </mat-toolbar>

  <form class="form" [formGroup]="form">

    <mat-card-content>
      <div class="form-content">
        <mat-card-subtitle>Dados do usuario</mat-card-subtitle>

        <div class="form-input">
          <mat-icon class="icon" matTooltip="Nome">account_circle</mat-icon>
          <div style="width: 80%;">
            <input class="input" type="text" [placeholder]="partner.name" formControlName="name"
            [ngClass]="{'input-invalid': form.controls.name.value[0] === ' '}">
            <span class="input-error" *ngIf="form.controls.name.value[0] === ' '">O nome não pode começar com espaço em branco!</span>
          </div>
        </div>

        <div class="form-input">
          <mat-icon class="icon" matTooltip="Email">alternate_email</mat-icon>
          <div style="width: 80%;">
            <input class="input" type="text" [placeholder]="partner.email" formControlName="email"
            [ngClass]="{'input-invalid': emailBlurred && form.controls.email.invalid}" (blur)="emailBlurred = true">
            <span class="input-error" *ngIf="emailBlurred && form.controls.email.invalid">Email inválido!</span>

          </div>
        </div>


        <div class="form-input">
          <mat-icon class="icon" matTooltip="Telefone">phone</mat-icon>
          <div style="width: 80%;">
            <input class="input" type="text" [placeholder]="partner.phone" formControlName="phone" [dropSpecialCharacters]="false" mask="(00) 00000-0000" [ngClass]="{'input-invalid': form.controls.phone.invalid}">
            <span class="input-error" *ngIf="form.controls.phone.invalid">Telefone inválido!</span>
          </div>
        </div>

        <div class="form-input">
          <mat-icon class="icon" matTooltip="PIX">attach_money</mat-icon>
          <div style="width: 80%;">
            <input class="input" type="text" placeholder="Chave Pix" formControlName="pixCode" [mask]="calculatedMask"
            [ngClass]="{'input-invalid': form.controls.pixCode.invalid}">
            <span class="input-error" *ngIf="form.controls.pixCode.invalid">PIX inválido!</span>
          </div>
        </div>
      </div>
    </mat-card-content>

    <mat-card-content class="end-line">
      <div class="form-content">
        <mat-card-subtitle>Endereço</mat-card-subtitle>

        <div class="form-input">
          <mat-icon class="icon" matTooltip="CEP">local_post_office</mat-icon>
          <div style="width: 80%;">
            <input class="input" type="text" [placeholder]="partner?.address?.postalCode  || 'CEP'" formControlName="postalCode" mask="00000-000" [dropSpecialCharacters]="false"
            [ngClass]="{'input-invalid': form.controls.postalCode.invalid}" (ngModelChange)="getAddress()">
            <span class="input-error" *ngIf="form.controls.postalCode.invalid">CEP inválido!</span>
          </div>
        </div>

        <div class="form-input">
          <mat-icon class="icon" matTooltip="Estado">account_balance</mat-icon>
          <div style="width: 80%;">
            <input class="input" type="text" [placeholder]="partner?.address?.state || 'Estado'" formControlName="state">
          </div>
        </div>

        <div class="form-input">
          <mat-icon class="icon" matTooltip="Cidade">business</mat-icon>
          <div style="width: 80%;">
            <input class="input" type="text" [placeholder]="partner?.address?.city || 'Cidade'" formControlName="city">
          </div>
        </div>

        <div class="form-input">
          <mat-icon class="icon" matTooltip="Bairro">place</mat-icon>
          <div style="width: 80%;">
            <input class="input" type="text" [placeholder]="partner?.address?.district || 'Bairro'" formControlName="district">
          </div>
        </div>

        <div class="form-input">
          <mat-icon class="icon" matTooltip="Rua">my_location</mat-icon>
          <div style="width: 80%;">
            <input class="input" type="text" [placeholder]="partner?.address?.street || 'Rua'" formControlName="street">
          </div>
        </div>

        <div class="form-input">
          <mat-icon class="icon" matTooltip="Número">home</mat-icon>
          <div style="width: 80%;">
            <input class="input" type="text" [placeholder]="partner?.address?.number || 'Número' " formControlName="number">
          </div>
        </div>

        <div class="form-input">
          <mat-icon class="icon" matTooltip="Complemento">more_horiz</mat-icon>
          <div style="width: 80%;">
            <input class="input" type="text" [placeholder]="partner?.address?.number || 'Complemento' " formControlName="complement">
          </div>
        </div>

      </div>
    </mat-card-content>

      <div class="form-content">
        <div class="form-input">
          <mat-slide-toggle color="primary" class="switch" formControlName="active">Status</mat-slide-toggle>
        </div>
      </div>

    <div class="form-button">
      <button mat-button class="cancel-button" (click)="closeView()">Cancelar</button>
      <button mat-raised-button class="send-button" (click)="updatePartner()" [disabled]="form.invalid || form.controls.name.value[0] === ' '"
      [ngClass]="{'disabled-button': form.invalid || form.controls.name.value[0] === ' '}">Enviar</button>
    </div>
  </form>
</div>
