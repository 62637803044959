<mat-card *ngIf="valueToken" class="side-menu-container">
  <mat-card-content class="side-menu-header">
    <h2 class="title">ADM Programa de Parceiros</h2>
  </mat-card-content>
  <mat-card-content class="side-menu-buttons">
    <button fxLayoutAlign="start center" mat-button class="buttons" (click)="navigateTo('home')">
      <mat-icon class="icon">group</mat-icon>
      <span class="title">Parceiros</span>
    </button>
    <button fxLayoutAlign="start center" mat-button class="buttons" (click)="navigateTo('dashboard')">
      <mat-icon class="icon">dashboard</mat-icon>
      <span class="title">Relatório</span>
    </button>
    <button fxLayoutAlign="start center" mat-button class="buttons" (click)="logOut()">
      <mat-icon class="icon">exit_to_app</mat-icon>
      <span class="title">Logout</span>
    </button>
  </mat-card-content>
</mat-card>
