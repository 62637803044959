import { PartnersService } from './../../services/partners/partners.service';
import { CreatePartnerComponent } from './../../modal/create-partner/create-partner.component';
import { ViewPartnersComponent } from './../../modal/view-partners/view-partners.component';
import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { WalletsInfoPartnersComponent } from 'src/app/modal/wallets-info-partners/wallets-info-partners.component';

@Component({
  selector: 'app-partners-list',
  templateUrl: './partners-list.component.html',
  styleUrls: ['./partners-list.component.scss']
})
export class PartnersListComponent implements OnInit {
  public partners:any = [];
  public page = 1;
  public search = '';
  public id: any;
  // public active: boolean;

  constructor(
    private matDialog: MatDialog,
    private partnersService: PartnersService
    ) { }

  ngOnInit(): void {
  this.getPartners();

  }

  openPage(id: any){
    this.partnersService.getAccessToken(id).subscribe( (res: any) => {
      if(res.success) {
        let url = `${environment.PARTNER_PROGRAM_URL}?access_token=${res.accessToken}`;
        window.open(url, '_blank')
        // console.log(res);
      }
    })
  }

  eraserAll(){
    this.partners = [];
    this.page = 1;
    this.getPartnerByName();
  }

  getPartnerByName(){
    this.partnersService.getPartners(this.page, this.search).subscribe((res: any) =>{
      if(res.success == true) {
        this.partners = this.partners.concat(res.data)
      }
    })
  }

  onScroll(){
    this.page++;
    if(this.search){
      this.getPartnerByName()
      return
    }
    this.getPartners();
  }

  getPartners(){
    this.partnersService.getPartners(this.page).subscribe((res: any) =>{
      if(res.success == true) {
        this.partners = this.partners.concat(res.data)
      }
    })
  }

  openPartnersDialog(partner: any){
   const dialogRef = this.matDialog.open(ViewPartnersComponent, {
    width: '600px',
    height: '700px',
    data: { partner }
   })

  //  console.log(partner);

   dialogRef.afterClosed().subscribe(result => {
    if(!result) {
      return
    }
    this.partners = [];
    this.page = 1;
    this.getPartners()
   })
  }

  openPartnersWalletDialog(id: any){
    const dialogRef = this.matDialog.open(WalletsInfoPartnersComponent, {
      width: '600px',
      height: '700px',
      data: {id}
     })
     
  }
}
