import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor(private authService: AuthService,
    private router: Router){}

  canActivate(){
     if(this.authService.hasToken()) {
      return true;
     }
     return this.router.navigate([''])
  }


}
