import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit{
  @Input() isLogin = false;
  showFiller = false;
  valueToken = true;

  constructor(private router: Router,
    private authService: AuthService){}

  ngOnInit(): void {
      this.authService.showHasTokenObs().subscribe((res: any) =>{
      this.valueToken = res;
    })
  }



  logOut(){
    this.authService.showHasToken(false)
    this.authService.logOut()
  }

  navigateTo(page: string) {
    this.router.navigate([`/${page}`]);
  }
}
