import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PartnersService } from 'src/app/services/partners/partners.service';

@Component({
  selector: 'app-wallets-info-partners',
  templateUrl: './wallets-info-partners.component.html',
  styleUrls: ['./wallets-info-partners.component.scss']
})
export class WalletsInfoPartnersComponent implements OnInit {

  public partner: any;
  public withdraws:any;
  public form: any;
  public liquidated: any;


  constructor(
    private partnerService: PartnersService,
    private dialogRef: MatDialogRef<WalletsInfoPartnersComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {id: any},){}

  async ngOnInit() { 
    this.initForm()
    try{
      const partner:any = await this.partnerService.getPartnerById(this.data.id)
      const withdraws:any = await this.partnerService.getWalletById(this.data.id)
      // add property to object to show button
      withdraws.data.creditsToLiquidate.forEach((element:any) => {
        element.showButton = true
      });

      this.withdraws = withdraws.data.creditsToLiquidate.concat(withdraws.data.wallet)
      this.liquidated = withdraws.data.liquidateData
      this.partner = partner.data
    }
    catch(err){
      console.log(err);
      this.snackBar.open('Erro ao carregar dados', 'OK', 
      {
        duration: 3000,
      })
    }



  }
  initForm(){
    this.form = new FormControl('ALL')
  }

  async filterHistory(filter:string){
    console.log(filter);
    try{
      if(filter == 'ALL'){
        const withdraws:any = await this.partnerService.getWalletById(this.data.id)
        withdraws.data.creditsToLiquidate.forEach((element:any) => {
        element.showButton = true
      });
      this.withdraws = withdraws.data.creditsToLiquidate.concat(withdraws.data.wallet)
    } else if(filter == 'OPEN'){
      const withdraws:any = await this.partnerService.getWalletById(this.data.id)
      this.withdraws = withdraws.data.creditsToLiquidate
    }
    else{
      const withdraws:any = await this.partnerService.getWalletById(this.data.id, filter)
      this.withdraws = withdraws.data.wallet
    }
  } catch(err){
    console.log(err);
    this.snackBar.open('Erro ao filtrar', 'OK', 
    {
      duration: 3000,
    })
  }
  }

  async liquidateCredit(credit:any){
    try{
      await this.partnerService.liquidateCredit(credit, this.data.id)
      this.snackBar.open('Crédito liquidado com sucesso', 'OK',{
        duration: 3000,
      })
      this.ngOnInit()
    }
    catch(err){
      console.log(err);
      this.snackBar.open('Erro ao liquidar crédito', 'OK', 
      {
        duration: 3000,
      })
    }
  }

}
