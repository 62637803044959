import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { AuthService } from 'src/app/services/auth/auth.service';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form: any;
  public socialUser!: SocialUser;
  public innerWidth: any;
  public googleLogoURL = "https://raw.githubusercontent.com/fireflysemantics/logo/master/Google.svg";
  public isLoggedin: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private snackBar: MatSnackBar
    ) {
      this.matIconRegistry.addSvgIcon(
        "logo",
        this.domSanitizer.bypassSecurityTrustResourceUrl(this.googleLogoURL));
      this.innerWidth = window.innerWidth;
    }

  ngOnInit(): any {
    this.initForm()
    this.authService.showHasToken(false)
    if(localStorage.getItem('access_token')){
      this.authService.showHasToken(true)
      return this.router.navigate(['/home'])
    }
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      this.loginWithGoogle();
      this.isLoggedin = user != null;
      // console.log(this.socialUser);
    });
  }

  initForm() {
    this.form = new FormGroup({
      email: new FormControl('',[Validators.required]),
      password: new FormControl('',[Validators.required]),
    })
  }

  login(): void {
    this.authService.login(this.form.value)
    }

    loginWithGoogle(): void {
      // console.log('loginWithGoogle');
      this.authService.googleLogin(this.socialUser.idToken).subscribe((res: any) => {
        // console.log(res);
        if(res.success) {
          this.authService.showHasToken(true)
          localStorage.setItem('access_token',res.data.token);
          this.router.navigate(['/home'])
        } else {
          this.snackBar.open(res.message, 'Fechar', {
            duration: 3000,
          });
        }
      })
    }
}
