import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {

  public url = environment.BACKEND_URL + '/auth/partner-program-account'

  constructor(private http: HttpClient) { }

  getPartners(page: any, name = '', limit = 23){
    let url = `${this.url}?page=${page}&limit=${limit}&searchParam=${name}`
    return this.http.get(url)
  }

  getPartnerById(id: any){
    let url = `${this.url}/${id}`
    return this.http.get(url).toPromise()
  }
  getWalletById(id: any, filter: any = ''){
    let url = `${this.url}/wallet/${id}?type=${filter.toLowerCase()}`
    return this.http.get(url).toPromise()
  }

  getAccessToken(id: any){
    // console.log(id);
    let url = `${this.url}/access/${id}`
    return this.http.get(url)
  }

  updatePartner(id:any, body: any){
    let url = `${this.url}/${id}`
    return this.http.put(url, body)
  } 

  liquidateCredit(credit:any,id:any){
    let url = `${this.url}/wallet/liquidate/${id}`
    return this.http.put(url, {credit: credit}).toPromise()
  }


}
