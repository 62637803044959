import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CepService } from 'src/app/services/cep/cep.service';
import { PartnersService } from 'src/app/services/partners/partners.service';

@Component({
  selector: 'app-view-partners',
  templateUrl: './view-partners.component.html',
  styleUrls: ['./view-partners.component.scss']
})
export class ViewPartnersComponent implements OnInit {

  public form: any;
  public partner: any;
  public calculatedMask = "";
  public emailBlurred = false;
  public messageError = '';

  constructor(
    private partnersService: PartnersService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ViewPartnersComponent>,
    private cepService: CepService,
    @Inject(MAT_DIALOG_DATA) public data: {partner: any},){
    }

  ngOnInit(): void {
    this.partner = this.data.partner;
    // console.log(this.partner);
    this.initializeForm();


  }

  initializeForm() {
    this.form = new FormGroup({
      name: new FormControl("",),
      email: new FormControl("",[Validators.email]),
      phone: new FormControl(""),
      active: new FormControl(this.partner.users[0].active,),
      postalCode: new FormControl(this.partner?.address?.postalCode,),
      state: new FormControl(this.partner?.address?.state,),
      city: new FormControl(this.partner?.address?.city,),
      district: new FormControl(this.partner?.address?.district,),
      street: new FormControl(this.partner?.address?.street,),
      number: new FormControl(this.partner?.address?.number,),
      complement: new FormControl(this.partner?.address?.complement,),
      pixCode: new FormControl(this.partner?.bank?.pixCode)
    })
    this.calculateMask()
    this.form.get('pixCode').valueChanges.subscribe(() => {
      this.calculateMask()
    })

    // console.log(this.form.value)
  }

  updatePartner(){

    let changeInfo: any = {address: {}, bank: {}};
    let address = ['postalCode', 'state', 'city', 'district', 'street', 'number', 'complement'];
    let bank = ['pixCode'];
    Object.keys(this.form.value).forEach((key) => {
      if(address.includes(key)){
        changeInfo.address[key] = this.form.value[key]
      } else {
        changeInfo[key] = this.form.value[key]
      }
      if(bank.includes(key)){
        changeInfo.bank[key] = this.form.value[key]
      } else {
        changeInfo[key] = this.form.value[key]
      }
      if(changeInfo[key] === ''){
        delete changeInfo[key]
      }
    })
    const active = changeInfo.active;
    delete changeInfo.active
    changeInfo.users = [{
      active: active,
      user: this.partner.users[0].user,
      _id: this.partner.users[0]._id
    }]

    this.partnersService.updatePartner(this.partner._id, changeInfo).subscribe({
      next: (res: any) => {
        if(!res.success) {
          // return
        }
      },
      error: (err: any) => {
        this.snackBar.open(`${err.message} Logo não foi possível atualizar as informações!`, 'Fechar', {
              duration: 3000,
            });
            return
      },
      complete: () => {
        this.snackBar.open('Informações atualizadas com sucesso!', 'Fechar', {
              duration: 3000,
            });
            this.dialogRef.close(true);
      }
    });
  }

  closeView(){
    this.dialogRef.close(false);
  }

  calculateMask(){
    this.calculatedMask = ""
    let value = this.form.get('pixCode').value
    value = value.replace(/[^a-zA-Z0-9@.]/g, '')
    const length = value.length
    if(/[a-zA-Z]/.test(value)){
      if(value.includes('@')|| value.includes('.')){
        this.calculatedMask = ""
      } else if(value.length >= 32){
        this.calculatedMask = "AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA"
      } else {
        this.calculatedMask = ''
      }
      return
    }

    if(length == 11){
      const isCpf = this.validateCpf(value)
      if(isCpf){
        this.calculatedMask = "000.000.000-00||00.000.000/0000-00"
      } else {
        this.calculatedMask = "(00) 00000-0000||00.000.000/0000-00"
      }
      return
    }

    if(length >= 14){
      const isCnpj = this.validateCnpj(value)
      this.calculatedMask = "(00) 00000-0000||00.000.000/0000-00"
      if(!isCnpj){
        this.form.controls.pixCode.markAllAsTouched()
        this.form.controls.pixCode.setErrors({ invalidCnpj: true })
      } else {
        this.form.controls.pixCode.setErrors(null)
      }
      return
    }

    return this.calculatedMask = ""

  }

  validateCpf(cpf: string){
    var sum = 0;
    var remainder;
    cpf = cpf.replace(/\D/g, '');
    if (cpf == "00000000000") return false;

    for(let i = 1; i <= 9; i++){
      sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if(remainder == 10 || remainder == 11) remainder = 0;
    if(remainder != parseInt(cpf.substring(9, 10)) ) return false;
    sum = 0;
    for(let i = 1; i <= 10; i++){
      sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if(remainder == 10 || remainder == 11) remainder = 0;
    if(remainder != parseInt(cpf.substring(10, 11) ) ) return false;
    return true;
  }

  validateCnpj(cnpj: string){


    cnpj = cnpj.replace(/[^\d]+/g,'');
    if(cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;


    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(0)))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(1)))
          return false;

    return true;
  }

  getAddress(){
    let cep = this.form.value.postalCode
    if (cep.length < 9) return
    this.cepService.getAddressByCep(cep).subscribe((res: any) => {
      this.form.patchValue({
        street: res.street,
        district: res.district,
        city: res.city,
        state: res.state,
      })
    })
  }

}
